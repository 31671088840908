
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import HeaderTitle from "@/components/HeaderTitle.vue";
import ActionButton from "@/components/ActionButton.vue";
import { BundleItemModel } from '@/models/bundle_item';
import { BundleModel } from '@/models/bundle';
import { BundleList } from '@/collections/bundles';
import { DimensionModel } from '@/models/dimension';
import { FilterDimensionModel } from '@/models/filter_dimension';
import {displayAlert} from "@/services/alert";

@Component({
  components: {
    HeaderTitle,
    ActionButton,
  },
})
export default class BundleMapping extends Vue {
  public bundles: BundleList = new BundleList();
  public bundleHeaders: any = [
    { text: "Product", value: "name" },
    { text: "Child Items", value: "bundleItems" },
    { text: "Actions", value: "actions", sortable: false, width: "200px" }
  ];
  public bundleItemHeaders: any = [
    { text: "Name", value: "name" },
    { text: "Quantity", value: "quantity" },
    { text: "Actions", value: "actions", sortable: false, width: "200px" }
  ];
  public dialogEdit: boolean = false;
  public editingBundle: BundleModel = new BundleModel();
  public dialogAddBundle: boolean = false;
  public newBundle: BundleModel = new BundleModel();
  public newBundleItem: BundleItemModel = new BundleItemModel();
  public editingBundleItem: BundleItemModel = new BundleItemModel();
  public saveLoading: boolean = false;
  public bundleItemLoading: boolean = false;
  public pushChangesLoading: boolean = false;
  public dialogDelete: boolean = false;
  public dialogManageItems: boolean = false;
  public quantity: number = 1;
  public search: string = '';
  public variants: any =  [];
  public selectedVariant: any = [];
  public dialogDeleteBundleItem: boolean = false;
  public isFormValid: boolean = false;
  public numberRule: any = [
    this.isNumber,
  ];
  public variantRules: any = [
    this.isSelectedVariant,
  ];

  public debouncedLoadVariants: () => void = () => {};


  public async created() {
    this.selectedVariant = new FilterDimensionModel();
    this.selectedVariant.name = "";
    this.selectedVariant.id = 0;
    this.bundleItemLoading = true;
    await this.bundles.fetch();
    this.bundleItemLoading = false;
    // await this.loadVariants();
    this.debouncedLoadVariants = this.debounce(this.searchVariants, 500);
  }

  public isSelectedVariant(v: any) {
    if (!this.selectedVariant || !this.selectedVariant.id || this.selectedVariant.id === '0') {
      return "Please select a product variant";
    }
    return true;
  }

  public isNumber(v: any) {
    if (!v || isNaN(Number(v))) {
      return "Value must be a number";
    }
    return true;
  }

  public showDeleteBundleItemDialog(item: BundleItemModel) {
    this.dialogDeleteBundleItem = true;
    this.editingBundleItem = item;
  }

  public async searchVariants() {
    const dimension = new DimensionModel();
    dimension.name = "Product Variant";
    dimension.code = "by_variant";
    this.variants.dimension = dimension;
    const bundle: BundleModel = new BundleModel();
    this.variants = await bundle.fetchVariants({keyword: this.search});
  }

  public async loadVariants() {
    this.debouncedLoadVariants();
  }

  public debounce(fn: Function, delay: number) {
    let timeoutID: number | null = null;
    return (...args: any[]) => {
      if (timeoutID) clearTimeout(timeoutID);
      timeoutID = window.setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  }

  public setSelectedVariant() {
    if (!this.selectedVariant || this.selectedVariant.id === 0) {
      return;
    }
    this.newBundleItem.variantId = this.selectedVariant.id;
    this.newBundleItem.name = this.selectedVariant.name;
    this.newBundleItem.sku = this.selectedVariant.sku;
  }

  public setNewBundle() {
    if (!this.selectedVariant || this.selectedVariant.id === 0) {
      return;
    }
    this.newBundle.variantId = this.selectedVariant.id;
    this.newBundle.name = this.selectedVariant.name;
    this.newBundle.sku = this.selectedVariant.sku;
  }

  public setEditingBundle() {
    if (!this.selectedVariant || this.selectedVariant.id == 0) {
      return;
    }
    this.editingBundle.variantId = this.selectedVariant.id;
    this.editingBundle.name = this.selectedVariant.name;
    this.editingBundle.sku = this.selectedVariant.sku;
  }

  public async addBundleItem() {
    if (this.$refs.form){
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    }
    if (!this.isFormValid) {
      return;
    }
    this.saveLoading = true;
    const newBundleItem = new BundleItemModel();
    newBundleItem.quantity = this.quantity;
    newBundleItem.variantId = this.selectedVariant.id;
    newBundleItem.name = this.selectedVariant.name;
    newBundleItem.sku = this.selectedVariant.sku;
    this.editingBundle.bundleItems.addItem(newBundleItem);

    this.bundles.save();

    this.saveLoading = false;
  }

  public editBundle(item: BundleModel) {
    this.editingBundle = item;
    this.dialogEdit = true;
  }

  public showDeleteDialog(item: BundleModel) {
    this.dialogDelete = true;
    this.editingBundle = item;
  }

  public async deleteBundle(item: BundleModel) {
    this.saveLoading = true;
    this.editingBundle = item;
    this.bundles.removeItem(this.editingBundle);
    this.editingBundle = new BundleModel();

    this.bundles.save();

    this.dialogDelete = false;
    this.saveLoading = false;
  }

  public async deleteBundleItem() {
    this.saveLoading = true;
    this.editingBundle.bundleItems.removeItem(this.editingBundleItem);
    this.bundles.save();
    this.dialogDeleteBundleItem = false;
    this.saveLoading = false;
  }

  public showManageItemsDialog(item: BundleModel) {
    this.editingBundle = item;
    this.dialogManageItems = true;
    this.selectedVariant = { id: null, name: '', sku: '' };
  }

  public async saveBundle() {
    if (this.$refs.formEditBundle){
      (this.$refs.formEditBundle as Vue & { validate: () => boolean }).validate();
    }
    if (!this.isFormValid) {
      return;
    }
    this.saveLoading = true;
    await this.bundles.save();
    
    this.dialogEdit = false;
    this.saveLoading = false;
  }

  public async pushChanges() {
    this.pushChangesLoading = true;
    const result = await this.bundles.pushChanges();
    if (result) {
      displayAlert('Pushed changes successfully!');
    } else {
      displayAlert('Push changes failed');
    }
    this.pushChangesLoading = false;
  }

  public showAddBundleDialog() {
    this.newBundle = new BundleModel();
    this.dialogAddBundle = true;
  }

  public async addBundle() {
    if (this.$refs.formAddBundle){
      (this.$refs.formAddBundle as Vue & { validate: () => boolean }).validate();
    }
    if (!this.isFormValid) {
      console.log('form not valid');
      return;
    }

    try {
      this.saveLoading = true;
      this.bundles.add(this.newBundle);
      const result = await this.bundles.save();
      if (result) {
        this.saveLoading = false;
        displayAlert('Added bundle successfully!');
        this.dialogAddBundle = false;
      }
    } catch (e) {
      //console.log(e);
      displayAlert('Failed to add bundle!');
    }
    this.saveLoading = false;
  }

}
