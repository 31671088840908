
import { Component, Vue, Prop } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { StoreModel } from '@/models/store';
import moment from 'moment';
import ActionButton from "@/components/ActionButton.vue";
import { EventBus } from "@/main";
import { FunnelEventModel } from '@/models/funnelEvent';

@Component({
  components: {
    ActionButton,
  },
})
export default class OnboardCompleteConfirm extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public confirmRightReport: boolean = false;
  public confirmCorrectData: boolean = false;
  public topReports: string = '';
  public canAskToday: boolean = false;
  public dialogConfirmRightReport: boolean = false;
  public dialogConfirmCorrectData: boolean = false;
  public dialogUpdateTopReport: boolean = false;
  public dialogWrongDataExample: boolean = false;
  public dialogDataNotCorrect: boolean = false;
  public updateTopReportsMessage: string = '';
  public saveSettingLoading: boolean = false;
  public wrongDataExample: string = '';
  public saveWrongDataConfirmLoading: boolean = false;
  public saveCorrectDataConfirmLoading: boolean = false;

  public created() {
    this.initData();
    this.checkCanAskToday();
    this.showDialogConfirmRightReport();
    this.showDialogConfirmCorrectData();
  }

  public initData() {
    if (!this.store.config) {
      return;
    }

    if (this.store.config.topReports) {
      this.topReports = this.store.config.topReports || '';
    }
    if (this.store.config.confirmCorrectData === 'true') {
      this.confirmCorrectData = true;
    }
    if (this.store.config.confirmRightReport === 'true') {
      this.confirmRightReport = true;
    }
  }

  public checkCanAskToday() {
    if (this.store.currentPackage.name === 'Free') {
      this.canAskToday = false;
      return;
    }
    const now = moment();
    //get lastAskedDate from local storage
    let lastAskedDateStr: string = localStorage.getItem('lastAskedDate') || '';
    if (lastAskedDateStr !== '' && lastAskedDateStr !== null) {
      const lastAskedDate = moment(lastAskedDateStr);
      if (!lastAskedDate.isSame(now, 'day')) {
        this.canAskToday = true;
      } else {
        this.canAskToday = false;
      }
    } else {
      this.canAskToday = true;
    }
    //save the lastAskedDate to local storage
    localStorage.setItem('lastAskedDate', now.format());
  }

  public showDialogConfirmRightReport() {
    if (!this.confirmRightReport && this.canAskToday) {
      this.dialogConfirmRightReport = true;
    }
  }

  public showDialogConfirmCorrectData() {
    if (!this.confirmCorrectData && this.canAskToday && this.confirmRightReport) {
      this.dialogConfirmCorrectData = true;
    }
  }

  public showUpdateTopReport() {
    this.dialogUpdateTopReport = true;
  }

  public doNotSendTopReport() {
    this.dialogUpdateTopReport = false;
    this.openChatConfirmRightReport();
  }

  public sendTopReport() {
    this.dialogUpdateTopReport = false;
    this.updateSetting();
    this.openChatConfirmRightReport(true);
  }
  public async updateSetting() {
    this.store.config.topReports = this.topReports;
    this.store.config.confirmCorrectData = this.confirmCorrectData;
    this.store.config.confirmRightReport = this.confirmRightReport;
    try {
      await this.store.saveSettings();
      EventBus.$emit('show-snackbar', {
        message: 'Thanks for updating your preferences!',
        color: 'success',
      });
    } catch (error: any) {
      EventBus.$emit('show-snackbar', {
        message: 'Error updating your preferences!',
        color: 'error',
      });
    }
  }

  public confirmRightReportAction(value: boolean) {
    this.confirmRightReport = value;
    this.dialogConfirmRightReport = false;
    this.updateSetting();
    if (value) {
      this.updateTopReportsMessage = 'To better understand your needs and improve our service, please update your top reports';
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'CONFIRMED_CORRECT_REPORT',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    } else {
      this.updateTopReportsMessage = 'Our product team will help you to set up the right reports. Please update your top reports';
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'CONFIRMED_INCORRECT_REPORT',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    }
    this.dialogUpdateTopReport = true;
  }

  public confirmCorrectDataAction(value: boolean) {
    this.confirmCorrectData = value;
    if (value) {
      this.saveCorrectDataConfirmLoading = true;
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'CONFIRMED_CORRECT_DATA',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    } else {
      this.saveWrongDataConfirmLoading = true;
      try {
        const funnelEvent = new FunnelEventModel({
          name: 'CONFIRMED_INCORRECT_DATA',
        });
        funnelEvent.create();
      } catch (error) {
        // skipped
      }
    }
    this.updateSetting();
    if (!value) {
      this.dialogWrongDataExample = true;
    }
    this.dialogConfirmCorrectData = false;
    if (value) {
      this.saveCorrectDataConfirmLoading = false;
    } else {
      this.saveWrongDataConfirmLoading = false;
    }
  }

  public openChatConfirmRightReport(sendTopReport: boolean = false) {
    let message: string = '';
    if (sendTopReport) {
      message = 'Please help me to set up the right reports: ' + this.topReports;
    } else {
      if (this.confirmRightReport) {
        message = 'I got the right report, please help me to verify the data.';
      } else {
        message = 'I need help to find the right report.';
      }
    }
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
      // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }

  public openChatWrongDataExample(sendExample: boolean = false) {
    this.dialogWrongDataExample = false;
    let message: string = '';
    if (sendExample) {
      message = 'Wrong data example: ' + this.wrongDataExample;
    } else {
      if (this.confirmCorrectData) {
        message = 'I confirm that the data provided by Assisty is correct!';
      } else {
        message = 'I need help to correct the data!';
      }
    }
    // @ts-ignore: Unreachable code error
    this.$crisp.do('chat:open');
      // @ts-ignore: Unreachable code error
    this.$crisp.do('message:send', ['text', message]);
  }
}
