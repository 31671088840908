
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ActionButton extends Vue {
  @Prop({ default: true })
  public primary!: boolean;
  @Prop({ default: '' })
  public title!: string;
  @Prop({ default: '' })
  public link!: string;
  @Prop({ default: '' })
  public target!: string;
  @Prop({ default: '' })
  public icon!: string;
  @Prop({ default: 'default' })
  public size!: string;
  @Prop({ default: false })
  public loading!: boolean;
  @Prop({ default: false })
  public disabled!: boolean;
  @Prop({ default: false })
  public warning!: boolean;
  @Prop({ default: 'default' })
  public type!: string;
  @Prop({ default: false })
  public isIcon!: boolean;
  @Prop({ default: '' })
  public tooltip!: string;
  @Prop({ default: false })
  public block!: boolean;
  @Prop({default: 'ml-3'})
  public buttonClass!: string;
  @Prop({default: ''})
  public color!: string;

  public actionClick() {
    if (!this.link) {
      this.$emit('actionClick');
    }
  }

  public get getColor() {
    if (this.color) {
      return this.color;
    }
    if (this.primary) {
      if (this.warning) {
        return 'red';
      }
      if (this.type === 'action') {
        return 'orange';
      }
      return 'deep_sku_blue';
    }
  }

}
