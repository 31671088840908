
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {del, get, post} from '@/services/http';
import { StoreModel } from '@/models/store';
import ActionButton from '@/components/ActionButton.vue';
import Alert from '@/components/Alert.vue';
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';
import {displayAlert} from "@/services/alert";
import moment from 'moment';
import dateFormat from 'dateformat';
import {isShopifyEmbedded} from '@shopify/app-bridge/utilities';
import {Redirect} from '@shopify/app-bridge/actions';

@Component({
  components: {
    ActionButton,
    Alert,
    GoogleDriveSelector,
  },
})
export default class QuickbooksIntegration extends Vue {
  @Prop({default: null})
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public hasConnection: boolean = false;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public loadingTriggerEtl: boolean = false;
  public updatedTime: string | null = null;

  public async created() {
    this.loading = true;
    try {
      const response: any = await get('/quickbooks/connection');
      if (response.status === true) {
        this.hasConnection = true;

        let etlProductsStartTime: any = null;
        let etlPurchaseOrdersStartTime: any = null;
        for (const etlStatus of this.store.etlStatuses.items) {
          if (etlStatus.etlType !== 'etl_data_period') {
            continue;
          }
          if (etlStatus.resource === 'quickbooks_products') {
            etlProductsStartTime = etlStatus.pendingEndTime;
          } else if (etlStatus.resource === 'quickbooks_purchase_orders') {
            etlPurchaseOrdersStartTime = etlStatus.pendingEndTime;
          }
        }
        const lastUpdatedTime = etlPurchaseOrdersStartTime > etlProductsStartTime ? etlPurchaseOrdersStartTime : etlProductsStartTime;
        this.updatedTime = dateFormat(
            lastUpdatedTime,
            'mmm d, yyyy HH:MM:ss',
            false
        );
      }
    } catch (e) {
      this.hasConnection = false;
    }

    this.loading = false;
    this.success = true;
  }

  public async actionClickConnect() {
    try {
      const clientId = process.env.VUE_APP_QUICKBOOKS_AUTH_CLIENT_ID;
      const scope = 'com.intuit.quickbooks.accounting';
      const redirectUri = `${process.env.VUE_APP_HOST}/quickbooks/oauth2callback`;
      const url = `https://appcenter.intuit.com/app/connect/oauth2?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code&state=assisty#/OpenIdAuthorize`;
      window.location.assign(url);
    } catch (e) {
    }
  }

  public async actionClickDisconnect() {
    this.loading = true;
    try {
      const response: any = await del(`/quickbooks/connection`);
      if (response.status === true) {
        displayAlert(`Disconnected QuickBooks successfully`);
      }
    } catch (e) {
      console.log(e);
    }
    this.hasConnection = false;
    this.loading = false;
  }

  public async triggerUpdateData() {
    this.loadingTriggerEtl = true;
    try {
      let etlProductsStartTime: any = null;
      let etlPurchaseOrdersStartTime: any = null;
      let etlEndTime = moment().toISOString();
      for (const etlStatus of this.store.etlStatuses.items) {
        if (etlStatus.etlType !== 'etl_data_period') {
          continue;
        }
        if (etlStatus.resource === 'quickbooks_products') {
          etlProductsStartTime = etlStatus.pendingEndTime;
        } else if (etlStatus.resource === 'quickbooks_purchase_orders') {
          etlPurchaseOrdersStartTime = etlStatus.pendingEndTime;
        }
      }

      const response1: any = await post('/etl_data_periods/manual_create', {
        merchantId: this.store.id,
        resourceType: 'quickbooks_products',
        periodType: 'INCREMENTAL',
        endTime: etlEndTime,
        startTime: etlProductsStartTime
      });
      const response2: any = await post('/etl_data_periods/manual_create', {
        merchantId: this.store.id,
        resourceType: 'quickbooks_purchase_orders',
        periodType: 'INCREMENTAL',
        endTime: etlEndTime,
        startTime: etlPurchaseOrdersStartTime
      });

      this.updatedTime = dateFormat(
          etlEndTime,
          'mmm d, yyyy HH:MM:ss',
          false
      );

      displayAlert("It will take a few minutes to sync data from QuickBooks");
    } catch (e) {
      console.log(e);
    }
    this.loadingTriggerEtl = false;
  }

  public async viewReport() {
    const url = `/reports/measures/incoming_quantity?dimension=by_variant`;
    if (isShopifyEmbedded()) {
      Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          `https://${this.store.shopName}/admin/apps/${process.env.VUE_APP_NAME}${url}`,
      );
    } else {
      window.location.assign(url);
    }
  }

  private validateEmail(email: string) {
    const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegexp.test(email);
  }

  private sleep(ms: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
}
