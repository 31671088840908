
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import Installing from '@/components/Installing.vue';
import { StoreModel } from '@/models/store';
import { BillingPackageModel } from '@/models/billing_package';
import { UserPackageModel } from '@/models/user_package';
import { BillingPackageList } from '@/collections/billing_packages';
import { isShopifyEmbedded } from '@shopify/app-bridge/utilities';
import { Redirect, History } from '@shopify/app-bridge/actions';
import { EventHandler } from '@/modules/events';
import { EventBus } from '@/main';

@Component({
  components: {
    HeaderTitle,
    Installing,
  },
})
export default class OnboardPricing extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  @Prop({ default: () => [] })
  public timeRangeLimit!: string[];
  @Prop({ default: false })
  public isAthenaFinished!: boolean;
  @Prop({ default: null })
  public app!: any;
  public success: boolean = false;
  public error: boolean = false;
  public loading: boolean = false;
  public billingPackages: BillingPackageList = new BillingPackageList();
  public filteredBillingPackages: BillingPackageList = new BillingPackageList();
  public newUserPackage: UserPackageModel = new UserPackageModel();
  public freeBillingPackage: BillingPackageModel = new BillingPackageModel();
  public paidBillingPackage: BillingPackageModel = new BillingPackageModel();
  public backupUserPackage: UserPackageModel = new UserPackageModel();
  public isYearly: boolean = false;
  public submitting: boolean = false;
  public maxFeatureLines: number = 7;
  public viewAllFeatures: boolean = false;
  public reviews: any = [
    { src: 'review1.png' },
    { src: 'review2.png' },
    { src: 'review3.png' },
    { src: 'review4.png' },
    { src: 'review5.png' },
    { src: 'review6.png' },
    { src: 'review7.png' },
    { src: 'review8.png' },
    { src: 'review9.png' },
  ];
  public features: any = [
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.38.09.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.18.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.28.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.34.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.41.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.48.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.49.55.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.41.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.29.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.53.18.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.56.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.24.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.52.08.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.51.14.png',
    },
    {
      src: 'https://userimg-bee.customeriomail.com/images/client-env-121888/Screen%20Shot%202022-12-08%20at%2008.50.43.png',
    },
  ];

  public async created() {
    localStorage.setItem('onboard_page', '/onboard-pricing');
    this.loading = true;
    try {
      await this.billingPackages.fetch();
      this.getBillingPackage();
      this.error = false;
      this.success = true;
    } catch (e: any) {
      this.error = true;
      this.success = false;
    }
    this.loading = false;
  }

  public get canSubscribePaidPlan() {
    const restrictedPlans = ['developer', 'test'];
    const shopifyPlan = this.store.information.plan_display_name.toLowerCase();
    const assistyTestStores = ['assisty-demo.myshopify.com', 'dariustest2.myshopify.com', 'the-best-megastore-test.myshopify.com'];
    for (const store of assistyTestStores) {
      if (this.store.shopName === store) {
        return true;
      }
    }
    for (const plan of restrictedPlans) {
      if (shopifyPlan.includes(plan)) {
        return false;
      }
    }
    return true;
  }

  public calcualteYearlyPrice(packagePrice: number) {
    let price: number = packagePrice;
    if (this.isYearly) {
      price = (10 * price) / 12;
      return price.toFixed(1);
    }
    return price;
  }

  public getBillingPackage() {
    const mappingPlans: any = [
      {
        shopify: 'Plus',
        assisty: 'Shopify Plus',
      },
      {
        shopify: 'Advanced',
        assisty: 'Advanced Shopify',
      },
      {
        shopify: 'Basic',
        assisty: 'Basic Shopify',
      },
      {
        shopify: 'Shopify',
        assisty: 'Professional',
      },
    ];
      
    const shopifyPlan = this.store.information.plan_display_name.toLowerCase();
    let assistyPlan = 'Professional';
    for (const item of mappingPlans) {
      if (shopifyPlan.includes(item.shopify.toLowerCase())) {
        assistyPlan = item.assisty;
        break;
      }
    }

    for (const item of this.billingPackages.items) {
      if (item.price === 0) {
        this.freeBillingPackage = item;
      } else if (assistyPlan === item.name) {
        this.paidBillingPackage = item;
      }
    }
    this.filteredBillingPackages = new BillingPackageList();
    if (this.canSubscribePaidPlan) {
      
      this.filteredBillingPackages.add(this.paidBillingPackage);
    } else {
      this.filteredBillingPackages.add(this.freeBillingPackage);
    }
  }

  public getTranslatedLabel(module: string, text: string) {
    if (this.$te(`${module}.${text}`)) {
      return this.$t(`${module}.${text}`);
    } else {
      return text;
    }
  }

  public isMaxFeature(index: number) {
    if (this.viewAllFeatures) {
      return false;
    }
    if (index < this.maxFeatureLines) {
      return false;
    }
    return true;
  }

  public async submitPackage(
    item: BillingPackageModel,
    trialDays: number = 14,
  ) {
    this.submitting = true;
    this.newUserPackage.billingPackageId = item.id;
    let returnUrl = `${process.env.VUE_APP_SUBSCRIPTION_RETURN_URL}`;
    if (isShopifyEmbedded()) {
      returnUrl = `https://admin.shopify.com/store/${this.store.shopName.split('.')[0]}/apps/${process.env.VUE_APP_NAME}/subscription/confirm`;
    }
    const confirmationUrl = await this.newUserPackage.create(
      trialDays,
      0,
      returnUrl,
      [],
      this.isYearly
    );

    if (this.newUserPackage.status === 'CONFIRMING' && confirmationUrl) {
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        await eventHandler.track('ONBOARDING_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
        await this.store.sendEvent('ONBOARDING_CONFIRMING_PAID_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }

      if (isShopifyEmbedded()) {
        Redirect.create(this.app).dispatch(
          Redirect.Action.REMOTE,
          confirmationUrl,
        );
      } else {
        window.location.assign(confirmationUrl);
      }
    } else {
      await this.store.updateInstallationStatus();
      try {
        const eventHandler = new EventHandler({
          store: this.store,
        });
        await eventHandler.track('ONBOARDING_START_FREE_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
        await this.store.sendEvent('ONBOARDING_START_FREE_PACKAGE', {
          billing_package_id: this.newUserPackage.billingPackageId,
        });
      } catch (e) {
        // ignore this
      }
      EventBus.$emit('check-athena-finish-interval');
      this.$router.push('/subscription');
    }
  }
}
