import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import dateFormat from 'dateformat';
import { get, post, del, put } from '@/services/http';
import { MeasureModel } from '@/models/measure';
import { DimensionModel } from '@/models/dimension';
import { AnalyticsTypeModel } from '@/models/analytics_type';
import { FilterColumnModel } from '@/models/filter_column';
import { FilterColumnList } from '@/collections/filter_columns';
import { ParamList } from '@/collections/params';
import { ParamModel } from '@/models/param';
import { ComparisonDateModel } from '@/models/comparison_date';
import { MetricList } from '@/collections/metrics';

import { Md5 } from 'ts-md5';
import axios from 'axios';
import { MetricModel } from './metric';

interface SortOrder {
  dashboard: any;
}


interface Option {
  sortBy?: any;
  sortDesc?: any;
  page?: string;
  itemsPerPage?: string;
  multiSort?: boolean;
}

export class ReportCardModel {
  public id: number;
  public name: string;
  public measure: MeasureModel;
  public dimension?: DimensionModel;
  public analyticsType?: AnalyticsTypeModel;
  public filterTimerange?: string[];
  public defaultRange: string = '30d';
  public filterColumns: FilterColumnList;
  public createdAt?: string;
  public updatedAt?: string;
  public pivotOption: string = '';
  public reportColumns: any = [];
  public reportParams: ParamList;
  public isDetail: boolean = true;
  public typeReport: string = 'TABLE';
  public perPage: number = 5;
  public page: number = 1;
  public cardWidthSize: number = 12;
  public cardHeightSize: number = 1;
  public sort: SortOrder;
  public dashboardIds: number = 0;
  public productSegmentId: number = 0;
  public pageOptions: Option = {};
  public comparisonDate!: ComparisonDateModel;
  public calculatedMetrics: MetricList = new MetricList();

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel();
    this.filterColumns = new FilterColumnList();
    this.sort = data.sort;
    this.pivotOption = '';
    this.reportColumns = [];
    this.reportParams = new ParamList();
    this.productSegmentId = data.productSegmentId;
    this.pageOptions = {};
    this.filterTimerange = [];
    this.comparisonDate = new ComparisonDateModel();
    this.calculatedMetrics = new MetricList();
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.measure = new MeasureModel({ code: data.measureCode });
    this.dimension = new DimensionModel({ code: data.dimensionCode });
    this.sort = data.sort;
    if (data.analyticsTypeCode) {
      this.analyticsType = new AnalyticsTypeModel({ code: data.analyticsTypeCode });
    }
    if (data.filterColumn) {
      this.importFilterColumns(data.filterColumn);
    }
    this.defaultRange = data.timerange;
    this.pivotOption = data.pivotOption;
    if (data.reportColumns && data.reportColumns.length > 0) {
      this.reportColumns = data.reportColumns;
    }

    if (data.size) {
      this.cardHeightSize = data.size.height;
      this.cardWidthSize = data.size.width;
    }
    if (data.dashboardIds) {
      this.dashboardIds = Number(data.dashboardIds);
    }
    if (data.reportParams) {
      this.importReportParams(data.reportParams);
    }
    this.typeReport = data.chartType;
    if (data.productSegmentId) {
      this.productSegmentId = data.productSegmentId;
    }
    if (data.options && data.options.pageOptions) {
      this.pageOptions = data.options.pageOptions;
    }
    this.filterTimerange = [
      this.formatDatetime(data.startTime), this.formatDatetime(data.endTime), data.timerange];
    if (data.options && data.options.comparisonDate) {
      this.comparisonDate = data.options.comparisonDate;
    }
    if (data.options && data.options.calculatedMetrics) {
      this.importCalculatedMetrics(data.options.calculatedMetrics);
    }
  }

  public formatDatetime(datetime: string, format: string = 'yyyy-mm-dd') {
    return dateFormat(datetime, format, false);
  }

  public importFilterColumns(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.filterColumns = new FilterColumnList();
    for (const values of data.items) {
      const filterColumn: FilterColumnModel = new FilterColumnModel();
      filterColumn.mapData(values);
      this.filterColumns.add(filterColumn);
    }
  }

  public async create() {
    const params: any = {
      name: this.name,
      measureCode: this.measure.code,
      analyticsTypeCode: '',
      timerange: this.defaultRange,
      dimensionCode: '',
      filterColumn: '',
      dashboardIds: this.dashboardIds + '',
      size: {
        height: this.cardHeightSize,
        width: this.cardWidthSize,
      },
      options: {
        pageOptions: this.pageOptions,
        comparisonDate: this.comparisonDate,
        calculatedMetrics: this.calculatedMetrics,
      },
    };
    if (this.dimension && this.dimension.code !== undefined) {
      params.dimensionCode = this.dimension.code;
    }
    if (this.analyticsType && this.analyticsType.code !== undefined) {
      params.analyticsTypeCode = this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange[0]) {
      params.startTime = this.formatDatetime(this.filterTimerange[0]);
    }
    if (this.filterTimerange && this.filterTimerange[1]) {
      params.endTime = this.formatDatetime(this.filterTimerange[1]);
    }

    if (this.filterColumns) {
      params.filterColumn = this.filterColumns;
    }
    if (this.reportParams && this.reportParams.items && this.reportParams.items.length > 0) {
      params.reportParams = this.reportParams;
    }
    if (this.reportColumns && this.reportColumns.length > 0) {
      params.reportColumns = this.reportColumns;
    }

    if (this.pivotOption) {
      params.pivotOption = this.pivotOption;
    }
    if (this.productSegmentId) {
      params.productSegmentId = this.productSegmentId;
    }
    if (this.typeReport) {
      params.chartType = this.typeReport;
    }
    if (this.productSegmentId) {
      params.productSegmentId = this.productSegmentId;
    }

    const res: any = await post('/cards', params);
    this.mapData(res.data);
  }

  public async removeDashboard() {
    this.dashboardIds = 0;
    await this.update();
  }

  public async update() {
    const params: any = {
      name: this.name,
      measureCode: this.measure.code,
      analyticsTypeCode: '',
      timerange: this.defaultRange,
      dimensionCode: '',
      filterColumn: '',
      dashboardIds: this.dashboardIds + '',
      size: {
        height: this.cardHeightSize,
        width: this.cardWidthSize,
      },
      options: {
        pageOptions: this.pageOptions,
        comparisonDate: this.comparisonDate,
        calculatedMetrics: this.calculatedMetrics,
      },
    };
    if (this.dimension && this.dimension.code !== undefined) {
      params.dimensionCode = this.dimension.code;
    }
    if (this.analyticsType && this.analyticsType.code !== undefined) {
      params.analyticsTypeCode = this.analyticsType.code;
    }
    if (this.filterTimerange && this.filterTimerange[0]) {
      params.startTime = this.formatDatetime(this.filterTimerange[0]);
    }
    if (this.filterTimerange && this.filterTimerange[1]) {
      params.endTime = this.formatDatetime(this.filterTimerange[1]);
    }
    if (this.filterColumns) {
      params.filterColumn = this.filterColumns;
    }
    if (this.reportColumns.length > 0) {
      params.reportColumns = this.reportColumns;
    }

    if (this.reportParams && this.reportParams.items && this.reportParams.items.length > 0) {
      params.reportParams = this.reportParams;
    }
    if (this.typeReport) {
      params.chartType = this.typeReport;
    }
    if (this.productSegmentId) {
      params.productSegmentId = this.productSegmentId;
    }
    const res: any = await put(`/cards/${this.id}`, params);
  }

  public async delete(id: number) {
    console.log('delete', id);
    const res: any = await del(`/cards/${id}`);
  }
  public async fetch() {
    if (!this.id) {
      return;
    }
    const res: any = await get(`/cards/${this.id}`);
    this.mapData(res.data);
  }

  private importReportParams(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.reportParams = new ParamList();
    for (const values of data.items) {
      const param: ParamModel = new ParamModel();
      param.mapData(values);
      this.reportParams.add(param);
    }
  }

  private importCalculatedMetrics(data: any = []) {
    if (!data) {
      return;
    }
    if (!Array.isArray(data.items)) {
      return;
    }
    this.calculatedMetrics = new MetricList();
    for (const values of data.items) {
      const metric: MetricModel = new MetricModel();
      metric.mapData(values);
      this.calculatedMetrics.add(metric);
    }
  }
}
