
import {Component, Prop, Vue} from 'vue-property-decorator';
import ActionButton from '@/components/ActionButton.vue';
import { post } from '@/services/http';
import {StoreModel} from '@/models/store';
import {displayAlert} from "@/services/alert";


@Component({
  components: {
    ActionButton,
  },
})
export default class QuickbooksOauth2Callback extends Vue {
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;

  public async mounted() {
    try {
      console.log(this.$route.fullPath);
      const realmId = this.$route.query.realmId.toString();
      const result = await this.saveOauth(this.$route.fullPath, realmId);
      if (result) {
        displayAlert(`Connected QuickBooks successfully`);
      }
    } catch (e) {}

    await this.$router.push('/settings/incoming-inventory');
  }

  private async saveOauth(fullPath: string, realmId: string) {
    const res: any = await post('/quickbooks/auth', {
      realmId: realmId,
      fullPath: fullPath
    });
    if (res.status) {
      return true;
    }
    return false;
  }
}
