
import { Component, Prop, Vue } from 'vue-property-decorator';
import HeaderTitle from '@/components/HeaderTitle.vue';
import ActionButton from '@/components/ActionButton.vue';
import { SpreadsheetTemplateModel } from '@/models/spreadsheet_template';
import { StoreModel } from '@/models/store';
import { SpreadsheetTemplateList } from "@/collections/spreadsheet_templates";
import GoogleDriveSelector from '@/components/GoogleDriveSelector.vue';

@Component({
  components: {
    HeaderTitle,
    ActionButton,
    GoogleDriveSelector,
  },
})
export default class SpreadsheetTemplates extends Vue {
  @Prop({ default: null })
  public app!: any;
  @Prop({ default: () => new StoreModel() })
  public store!: StoreModel;
  public isLoading = false;
  public spreadsheetTemplates = new SpreadsheetTemplateList();
  public templateHeaders: any = [
    { text: 'Name', value: 'name' },
    { text: 'Description', value: 'description' },
    { text: 'Actions', value: 'actions', sortable: false },
  ];
  public async created() {
    this.isLoading = true;
    await this.spreadsheetTemplates.fetch();
    this.isLoading = false;
  }
}
